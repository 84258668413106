import memberImg1 from '../assets/Members/member1.webp'
import memberImg2 from '../assets/Members/member2.webp'
import memberImg3 from '../assets/Members/member3.webp'
import memberImg4 from '../assets/Members/member4.webp'
import memberImg5 from '../assets/Members/member5.webp'

export const members = [
  {
    img: memberImg1,
    name: "Gloria Fonten",
    role: "\"General Manager\"",
    text: <p>
      Tireless traveler. With her head always full of fantasies. An inveterate dreamer. Traveled far and wide in the world accumulating a lot of experience but always faithful to her first love: Cuba, the land where she was born. Italy, on the other hand, the second home for 26 years, always accompanies her on a path of style, beauty, refinement, as only the Italian "dolce vita" could teach.<br />Trust her advice.
    </p>
  },
  {
    img: memberImg2,
    name: "Gabriele Paoletti",
    role: "\"Operations Manager\"",
    text: <p>Born in Florence.. (for Florentines, the navel of the world ) - A real traveler. Man of the sea: sailing boats, freediving, scuba diving: his passions!<br />In addition to Tuscan cuisine, the Florentine steak, and always, new great challenges... Cuba is always a great challenge... and he faces it, while designing unique naturalist and sports trails or from his cuisine, fusion, a smooth encounter between Italian savoir faire and intense Caribbean flavors.<br />A unique travel companion.</p>
  },
  {
    img: memberImg3,
    name: "Barbara Maffia",
    role: "\"Back Office Executive\"",
    text: <p>A Lake Como native. Experienced traveler and travel consultant for more than 20 years... Kind and caring, always ready to do more.<br />Booking and Back office in her hands. A modern heroine. Couldn’t do without her.</p>
  },
  {
    img: memberImg4,
    name: "Ailed Payrol",
    role: "\"Local agent and Team coordinator.\"",
    text: <p>As Ayled, our local agents are committed to sharing and protecting the beautiful places in which they live.<br />They are carefully selected for their ability to offer you exceptional service sharing their knowledge and passion for the place. They help make tourism a positive movement.<br />Ayled lives in Central Cuba and knows the destination better than anyone, so she, as our other local agents, will know how to surprise you and make you live unforgettable experiences.</p>
  },
  {
    img: memberImg5,
    name: "Matteo Minin",
    role: "\"Web Designer\"",
    text: <p>For us "matty", witty and patient to transform the company's ideas into design. The young and smart breath of the Cuba Luxury Holidays company.<br />His passion for technology made him a good self-taught programmer.<br />His dream: to become a software engineer.</p>
  }
]