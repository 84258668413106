import React from 'react'
import it from '../../assets/icons/it.png'
import en from '../../assets/icons/en.png'
import '../../css/admin/langPrompt.css'

const LangPrompt = () => {
  const setLang = (lang) => {
    console.log("ok")
    localStorage.setItem("lang", lang)
    window.location.reload()
  }

  return (
    <div className='langPrompt'>
      <div className="langPrompt__content">
        <p>Choose your language</p>
        <div className="langPrompt__content__button">
          <button onClick={() => setLang("it")}><img src={it} alt="Italian flag" />IT</button>
          <button onClick={() => setLang("en")}><img src={en} alt="British flag" />EN</button>
        </div>
      </div>
    </div>
  )
}

export default LangPrompt