import React, { useState } from 'react';
import { isAuthenticated } from './Auth';
import arrow from '../../assets/quotes/right-arrow.png';
import '../../css/admin/quotesGenerator.css';
import Loading from '../Loading';
import CustomPageForm from './card/CustomPageForm';
import CustomPageItemList from './card/CustomPageItemList';

function QuoteForm() {
  const [quote, setQuote] = useState({ name: "", customerName: "", items: [] })
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!isAuthenticated()) {
    window.location.href = '/login';
  }

  const scrollBottom = () => {
    document.getElementById('bottom').scrollIntoView({ behavior: 'smooth' });
  };
  const scrollTop = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  const handleChange = (e) => {
    setQuote({ ...quote, [e.target.name]: e.target.value })
    setError(null)
  }
  const handleNewItem = (newItem) => {
    setQuote({ ...quote, items: [...quote.items, newItem] })
    setError(null)
  }
  const handleRemove = (index) => {
    const items = quote.items.filter((item, i) => i !== index)
    setQuote({ ...quote, items })
    setError(null)
  }

  const handleDragEnd = (result) => {
    const start = result.source.index
    const end = result.destination ? result.destination.index : null

    if ((start === end) || end === null) return;

    const items = Array.from(quote.items)
    const [removed] = items.splice(start, 1)
    items.splice(end, 0, removed)

    setQuote({ ...quote, items })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(quote)

    try {
      const res = await fetch('https://cuba-luxury-backend.onrender.com/quote', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: JSON.stringify(quote),
      });
      if (res.status === 409) throw new Error("link already exists")
      else if (!res.ok) throw new Error("Can't submit the form, try again later")

      const { id } = await res.json()
      console.log(id)
      setQuote({ ...quote, id })
    } catch (error) {
      if (error.message === "link already exists")
        setError("Il link inserito è già stato utilizzato, inserirne un altro")
      else
        setError("Can't submit the form, try again later")
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="quote-form">
      {loading && <Loading />}
      <div className="scroll">
        <button className="scroll__bottom" onClick={scrollTop}>
          <img src={arrow} alt="arrow" />
        </button>
        <button className="scroll__top" onClick={scrollBottom}>
          <img src={arrow} alt="arrow" />
        </button>
      </div>

      <h1>Crea un preventivo</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Nome preventivo:
          <input
            type="text"
            name="name"
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Customer Name:
          <input
            type="text"
            name="customerName"
            onChange={handleChange}
          />
        </label>
        <label>
          Custom Link <span style={{ color: "red" }}>(inserisci solo la parte dopo lo /)</span>:
          <input
            type="text"
            name="link"
            onChange={handleChange}
          />
        </label>
        <CustomPageForm setLoading={setLoading} handleInsertion={handleNewItem} />
        <CustomPageItemList
          quote={quote}
          handleDragEnd={handleDragEnd}
          handleRemoveItem={handleRemove}
        />
        <button type="submit">Submit</button>
        {error && <p className='error'>{error}</p>}
        {
          (quote.id !== undefined) && (
            <p>
              Send this link: <a href={`https://cubaluxuryholidays.com/quote/${quote.link || quote.id}`}>link</a>
            </p>
          )
        }
      </form>
      <div id="bottom"></div>
    </div >
  );
}

export default QuoteForm;
