import React from 'react'

function OurTeamCard(props) {
  return (
    <div className="OurTeamCard">
        <div className="OurTeamCard__upper">
          <div className="OurTeamCard__upper__imgContainer">
            <img src={props.data.img} alt={props.data.name + " profile pic"} />
          </div>
          <div className="OurTeamCard__upper__generalInfo">
              <p className="name">{props.data.name}</p>
              <p className="role">{props.data.role}</p>
            </div>
        </div>

        <div className="OurTeamCard__bottom">
          {props.data.text}
        </div>
    </div>
  )
}

export default OurTeamCard