import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../Loading'
import { isAuthenticated } from './Auth'
import '../../css/admin/qrView.css'
import qr1 from '../../qrcode/qr1.png'
import qr2 from '../../qrcode/qr2.png'

const QrCard = ({ qr, onSubmit }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [newQr, setNewQr] = useState({ ...qr })

    const handleChange = (e) => {
        setNewQr({ ...newQr, [e.target.name]: e.target.value })
    }

    return (
        <div className='qrView__container__card'>
            <p className='title'>{qr.name}</p>
            <p className='link'>{qr.url}</p>
            {isEditing ?
                <>
                    <input
                        type="text"
                        name="url"
                        placeholder={qr.url}
                        onChange={handleChange}
                    />
                    <button
                        type="submit"
                        style={{ backgroundColor: 'green' }}
                        onClick={() => {
                            onSubmit(qr._id, newQr)
                            setIsEditing(false)
                        }}
                    >
                        Save
                    </button>
                </>
                :
                <>
                    <Link
                        to={`/qr/${qr._id}`}
                        target='_balnk'
                        rel='noopener noreferrer'
                    >
                        link
                    </Link>
                    <button
                        onClick={() => { setIsEditing(true) }}
                        style={{ backgroundColor: 'orange' }}
                    >
                        Modify
                    </button>
                </>
            }
        </div >
    )
}

const QrView = () => {
    const [qrs, setQrs] = useState([])
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)

    if (!isAuthenticated()) {
        window.location.href = '/login';
    }

    useEffect(() => {
        setLoading(true)
        setError(false)
        fetch("https://cuba-luxury-backend.onrender.com/qr")
            .then(res => res.json())
            .then(data => {
                setQrs(data)
            })
            .catch(error => { setError(true); console.log(error) })
            .finally(() => { setLoading(false) })
    }, [])

    const handleQrChange = (id, newQr) => {
        setLoading(true)

        setQrs(qrs.map(qr => qr._id === id ? newQr : qr))
        fetch(`https://cuba-luxury-backend.onrender.com/qr/${newQr._id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
            },
            body: JSON.stringify({ name: newQr.name, url: newQr.url })
        })
            .then(res => {
                if (!res.ok) throw new Error()
            })
            .catch(() => { setError(true) })
            .finally(() => { setLoading(false) })
    }

    return (
        <div className='qrView'>
            {loading && <Loading />}
            <h1>QR Codes</h1>
            <div className='qrView__container'>
                {qrs.map(qr => (
                    <QrCard
                        key={qr._id}
                        qr={qr}
                        onSubmit={handleQrChange}
                    />
                ))}
            </div>
            {error && <p className='error'>Errore, ricarica la pagina</p>}

            <p>Scarica i codici QR</p>
            <a className='download' href={qr1} download={"qr1.png"}>Scarica QR-1</a>
            <a className='download' href={qr2} download={"qr2.png"}>Scarica QR-2</a>
        </div>
    )
}

export default QrView