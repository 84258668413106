import React, { useEffect, useState } from 'react'
import ImageSlider from '../ImageSlider'
import Loading from '../Loading'
import '../../css/basicPageStructure.css'
import LinkAdder from '../LinkAdder'


const BasicPageStructure = ({ url }) => {
  const [page, setPage] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    fetch(url)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          setError(true)
        }
      })
      .then(data => {
        setPage(data)
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setError(true)
        setIsLoading(false)
      })
  }, [url])

  return (
    <div className='basic'>
      {isLoading && <Loading message={"Loading your page..."} />}
      {error ? <h1 className="error">Can't find your page,<br />please reload</h1> :
        <div className="basic">
          {page && page.customerName && <h1 className="basic__customerName">Hi {page.customerName}, explore the journey we created for you!</h1>}
          {page.items && page.items.map((item, key) =>
            <section className='basic__section' key={key}>
              {/* TITLE1 AND TITLE 2 */}
              {item.title && <div className={'basic__section__title ' + item.size}>
                {item.title.title1 && <h2><LinkAdder text={item.title.title1} /></h2>}
                {item.title.title2 && <h3><LinkAdder text={item.title.title2} /></h3>}
              </div>}
              {/* TITLE 3 */}
              {item.title3 && <div className={'basic__section__title ' + item.size}>
                <h2 className='title3'><LinkAdder text={item.title3} /></h2>
              </div>}
              {/* TESTO 1 */}
              {item.text && <div className={'basic__section__title ' + item.size}>
                <p className='text'><LinkAdder text={item.text} /></p>
              </div>}
              {/* SLIDER */}
              {item.slider.length > 0 && <div className='basic__section__slider noPrint'>
                {item.slider.length !== 1 ?
                  <ImageSlider slides={item.slider} /> :
                  <img className='slider__image' src={item.slider[0].image} alt="" />
                }
              </div>}
              {/* INTERUPTION */}
              {item.interruption && <div className='basic__section__interruption'>
                <div className="row noPrint"></div>
              </div>}
            </section>
          )}
        </div>
      }
    </div>
  )
}

export default BasicPageStructure