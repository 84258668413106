import { Link } from "react-router-dom"

const SantaClaraCard = ({ data }) => {
  return (
    <Link to={data.href} className="santaClara__sections__section">
      <h2>{data.title}</h2>
      <p>{data.text}</p>
      <div className="santaClara__sections__section__container">
        <div className="filter"></div>
        <img src={data.image} alt={data.altImage} />
        <div className="button">
          <p>Discover</p>
        </div>
      </div>
    </Link>
  )
}

export default SantaClaraCard