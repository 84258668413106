import React from 'react'
import { reviews } from '../../constants/aboutUs'
import '../../css/aboutUs.css'
import AboutUsCard from '../Cards/AboutUsCard'

function AboutUs() {
  return (
    <div className='AboutUs'>
      <h1>ABOUT US</h1>
      {reviews.map((review, index) => <>
        <AboutUsCard review={review} key={index} />
        {(index !== reviews.length - 1) && <div className="row"></div>}
      </>)}
    </div>
  )
}

export default AboutUs