import TimesLogo from '../assets/AboutUs/timesLogo.webp'
import TripadvisorLogo from '../assets/AboutUs/tripadvisorLogo.webp'
import image1 from '../assets/AboutUs/image001.webp'
import image2 from '../assets/AboutUs/image002.webp'
import image3 from '../assets/AboutUs/image003.webp'

export const reviews = [
  {
    text: <p>“…Appealing, tiny, new casa particular “Suite Florencia” at Candelaria 8 altos – calle Maestra Nicolasa – Santa Clara; the project of Florentine Gabriele and his Cuban wife Gloria. Gabriele is a chef and their breakfasts are a miracle, featuring a cornucopia of homemade jams and exotic fruits…”</p>,
    logo: TimesLogo,
    logoAlt: "The Times logo",
    reviewer: 'Lydia Bell',
    date: 'September 2018',
    image: image1,
    altImage: "suite florencia breakfast cuba"
  },
  {
    text: <p>“We spent one night in the beautiful and central Suite Florencia in Santa Clara. A 1930s colonial house kept as <span>a jewel by Gloria and Gabriele.</span><br />We received <span>a super welcome with lots of information about the city.</span> And then what about the breakfast that they prepared for us ... the best we have experienced in Cuba.<br />We were lucky enough to have <span>Gloria as a guide for our tour of the city...</span> she made us passionate about the history of her Santa Clara and visit some wonders, so much so as to make the city one of the most beautiful views in Cuba.<br /><span>Gloria and Gabriele are also tour operators</span>: if we return to Cuba <span>we will undoubtedly ask them to organize our trip.</span>”</p>,
    logo: TripadvisorLogo,
    logoAlt: "Tripadvisor logo",
    reviewer: "Valentina P.",
    date: "October 2022",
    image: image2,
    altImage: "local pictures of cuba"
  },
  {
    text: <p><span>L’ Excellence...</span><br /><br />"Suite Florencia represents everything you could wish for in an accommodation when traveling. The property is beautiful, well furnished, very clean, bright and fresh. The mattresses are new and very comfortable as are the bathrooms. Everything has been recently renovated and with great care. The plant-filled terrace, where a light breeze blows in the evening, is perfect for relaxing after exploring the nearby city center.<br /><br /><span>The real plus, however</span>, of Suite Florencia, <span>are Gloria and Gabriele</span>, who welcome you like one of the family and <span>are always available to give information, suggestions</span>, or simply to make you drink a rum in company in the shade of the terrace. The icing on the cake is Gabriele's magnificent culinary skill who, in a mix of Caribbean and Italian cuisine, creates extraordinary dishes, both for real dinners and for aperitifs. If you stay here you will absolutely have to stop and eat, you will thank me!<br /><br /><span>In conclusion, the best accommodation we stayed in on our long trip to Cuba</span>, for us it represented a caress after exhausting weeks of touring!! <span>We can't wait to be back soon!"</span></p>,
    logo: TripadvisorLogo,
    logoAlt: "Tripadvisor logo",
    reviewer: "Serena",
    date: "August 2022"
  },
  {
    text: <p><span>“A Classy establishment“</span><br /><br />Gloria and her Italian husband are great hosts – not only is this a delightful oasis in the middle of bustling Santa Clara but they really know how to look after their guests too. <br /><br />Gloria gave us really informative background to Santa Clara when we arrived so we could organize our stay; and her husband cooked us an amazing Italian dinner served on the terrace. <br /><br /><span>I am vegetarian, and this was one of the best meals I had in Cuba as it was really vegetarian</span>, not just a few vegetables. They served an excellent breakfast too. <br /><br /><span>What separates this casa from the many others I stayed in in Cuba, is its classy finish both in terms of its interior, but also all their service with the visitor’s needs in mind.</span> Deservedly number one in Santa Clara on Trip Advisor.</p>,
    logo: TripadvisorLogo,
    logoAlt: "Tripadvisor logo",
    reviewer: "UK guest",
    date: "November 2019",
    image: image3,
    altImage: "local pictures of cuba"
  },
  {
    text: <p>I stayed at this hotel and everything was good…<br />The Location optimal, right in the city center, walking distance from main attractions…<br />The Hotel itself is very nice, well kept and with good style.<br /><br />The room was very spacious and very clean, same thing for the bathroom very clean and spaciousBreakfast was generous, well served and various. Most of all, everything was handmade.<span>Gloria and Gabriele, the owners, have been extremely kind and welcoming at all timesGloria was great in helping us plan what places to visit in Santa Clara...<br /></span>Definitely a place to stay if Visiting Santa Clara, <span>100% recommended!</span></p>,
    logo: TripadvisorLogo,
    logoAlt: "Tripadvisor logo",
    reviewer: "London traveler",
    date: "November 2019"
  }
]