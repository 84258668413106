import React from 'react'
import { Link } from 'react-router-dom'
import "../css/menu.css"
import LuxuryLogo from '../assets/luxuryLogo.svg'
import HomesLogo from '../assets/homesLogo.svg'
import phoneIcon from '../assets/icons/phone.png'
import mailIcon from '../assets/icons/email.png'

function Menu() {
  return (
    <div className="Menu">
      <div className="Menu__upperContainer">
        <div className="Menu__upper">
          <div className="Menu__upper__logoContainer">
            <a href="/">
              <img
                className="Menu__upper__logo"
                src={LuxuryLogo}
                alt="Cuba Luxury Holidays Logo"
              />
            </a>
            <a href="/homes">
              <img
                className="Menu__upper__logo homesLogo"
                src={HomesLogo}
                alt="Cuba Luxury Homes Logo"
              />
            </a>
          </div>

          <div className="Menu__upper__contacts">
            <div className="contact phone">
              <img src={phoneIcon} alt="phone icon" />
              <a href="tel:+39 3926211584">+39 3926211584</a>
            </div>

            <div className="row">

            </div>

            <div className="contact">
              <img src={mailIcon} alt="mail icon" />
              <a href="mailto:sales@cubaluxuryholidays.com">sales@cubaluxuryholidays.com</a>
            </div>
          </div>
        </div>
      </div>
      <div className="Menu__bottom">
        <div className="Menu__bottom__container">
          <Link to="/our-team">OUR TEAM</Link>
          <Link to="/why-cuba">WHY CUBA?</Link>
          <Link to="/about-us">ABOUT US</Link>
          <Link to="/tours">TOURS</Link>
          <Link to="/santa-clara">SANTA CLARA</Link>
          <Link to="/contacts">CONTACTS</Link>
        </div>
      </div>
    </div>
  )
}

export default Menu