import React from 'react'
import '../css/loading.css'

const Loading = ({ message = "Loading..." }) => {
    return (
        <div className='loading-overlay'>
            <div className='loading'></div>
            <p className='loading-text'>{message}</p>
        </div>
    )
}

export default Loading