import React, { useState } from 'react'
import imageCompression from 'browser-image-compression';
import SizeInput from './SizeInput';
import '../../../css/admin/card/customPageForm.css'

const CustomPageForm = ({ setLoading, handleInsertion }) => {
    const defaultItem = {
        title: { title1: "", title2: "" },
        title3: "",
        text: "",
        slider: [],
        interruption: false
    }
    const [selectedType, setSelectedType] = useState("");
    const [newItem, setNewItem] = useState(defaultItem);
    const [error, setError] = useState(null)

    const handleSelectedType = (e) => {
        const type = e.target.value
        setSelectedType(type)

        if (type === "title") {
            setNewItem({ ...defaultItem, [type]: { title1: "", title2: "" }, size: "md" })
        } else if (type === "title3" || type === "text") {
            setNewItem({ ...defaultItem, [type]: "", size: "md" })
        } else if (type === "slider") {
            setNewItem({ ...defaultItem, slider: [] })
        } else if (type === "interruption") {
            setNewItem({ ...defaultItem, interruption: true })
        }
        setError(null)  //reset error
    }

    const handleNewItem = (e) => {
        setError(null)  //reset error

        if (e.target.name === "title1" || e.target.name === "title2") {
            setNewItem({ ...newItem, title: { ...newItem.title, [e.target.name]: e.target.value } })
        } else {
            setNewItem({ ...newItem, [e.target.name]: e.target.value })
        }
    }

    const handleImage = async (e) => {
        setLoading(true);
        setError(null)  //reset error

        const file = e.target.files[0]
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 900,
        }

        try {
            const compressedFile = await imageCompression(file, options)
            const formData = new FormData()
            formData.append('image', compressedFile)

            const res = await fetch('https://cuba-luxury-backend.onrender.com/imgUpload', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
                },
                body: formData,
            })

            if (!res.ok) {
                throw new Error('Image upload failed')
            }

            const imageUrl = await res.json()

            setNewItem({
                ...newItem,
                slider: [...newItem.slider, { image: imageUrl.url }]
            })
        } catch (err) {
            if (err.type === 'error') { // corrupted file
                setError("Error, can't read the image")
            } else {
                setError("Image upload failed")
            }
        } finally {
            setLoading(false);
        }
    }
    //TODO: reset form on submit

    const handleAddItem = () => {
        if (selectedType === 'slider' && newItem.slider.length === 0) {
            setError("Please, add at least one image")
            return
        } else if (selectedType === 'title' &&
            (newItem.title.title1 === "" && newItem.title.title2 === "")) {
            setError("Please, fill at least one field")
            return
        } else if (selectedType === 'title3' && newItem.title3 === "") {
            setError("Please, add title")
            return
        } else if (selectedType === 'text' && newItem.text === "") {
            setError("Please, add text content")
            return
        }

        handleInsertion(newItem)
        setSelectedType("")
    }

    return (
        <div className='customPageForm'>
            <label className='customPageForm__selectItem'>
                Item Type:
                <select onChange={handleSelectedType} value={selectedType}>
                    <option value="" disabled>Select an item type</option>
                    <option value="title">Title 1/2</option>
                    <option value="title3">Title 3</option>
                    <option value="text">Text</option>
                    <option value="slider">Gallery</option>
                    <option value="interruption">Interruption</option>
                </select>
            </label>

            {selectedType === 'title' && (
                <>
                    <label>
                        Title 1:
                        <input
                            type="text"
                            name="title1"
                            onChange={handleNewItem}
                        />
                    </label>
                    <label>
                        Title 2:
                        <input
                            type="text"
                            name="title2"
                            onChange={handleNewItem}
                        />
                    </label>
                    <SizeInput handleNewItem={handleNewItem} />
                </>
            )}
            {selectedType === 'title3' && (
                <>
                    <label>
                        Title 3:
                        <input
                            type="text"
                            name="title3"
                            onChange={handleNewItem}
                        />
                    </label>

                    <SizeInput handleNewItem={handleNewItem} />
                </>
            )}
            {selectedType === 'text' && <>
                <label>
                    Text:
                    <textarea
                        name="text"
                        onChange={handleNewItem}
                    />
                </label>

                <SizeInput handleNewItem={handleNewItem} />
            </>
            }
            {selectedType === 'slider' && (
                <>
                    <label>
                        Slider Images:
                        <input type="file" accept="image/*" onChange={handleImage} />
                    </label>

                    <div className="slider_container" style={{ display: "flex", flexWrap: "wrap" }}>
                        {newItem.slider !== undefined &&
                            newItem.slider.map(({ image }, index) => (
                                <>
                                    <a href={image} key={index}>
                                        <img
                                            src={image}
                                            alt="gallery"
                                            style={{ width: "250px", marginRight: "25px" }}
                                        />
                                    </a>
                                    <button type="button" onClick={() => { }}>Remove</button>
                                </>
                            ))}
                    </div>
                </>
            )}

            <button
                className='addItem'
                type="button"
                style={selectedType === "" ? { opacity: "0.6", cursor: "not-allowed" } : {}}
                onClick={handleAddItem}
                disabled={selectedType === ""}
            >
                Add Item
            </button>

            {error && <p className='error'>{error}</p>}
        </div>
    )
}

export default CustomPageForm