import React from 'react'
import BasicPageStructure from '../basicPages/BasicPageStructure'
import '../../css/santaClara/santaClaraPage.css'

const SantaClaraTours = () => {
  const lang = localStorage.getItem("lang")
  const url = () => {
    switch (lang) {
      case "en":
        return "https://cuba-luxury-backend.onrender.com/page/64e47aeb3cbe72068438420d";
      default: return "https://cuba-luxury-backend.onrender.com/page/64e47aeb3cbe72068438420d"
    }
  }
  return (
    <div className="santaClaraPage">
      <BasicPageStructure url={url()} />
    </div>
  )
}

export default SantaClaraTours