import React from 'react'
import HomesDepliant from './HomesDepliant'
import depliant from './pdf_apodaca12/Apodaca12-depliant.pdf'
import image1 from './pdf_apodaca12/01.jpg'
import image2 from './pdf_apodaca12/02.jpg'
import image3 from './pdf_apodaca12/03.jpg'
import image4 from './pdf_apodaca12/04.jpg'
import image5 from './pdf_apodaca12/05.jpg'
import image6 from './pdf_apodaca12/06.jpg'
import image7 from './pdf_apodaca12/07.jpg'
import image8 from './pdf_apodaca12/08.jpg'
import image9 from './pdf_apodaca12/09.jpg'
import image10 from './pdf_apodaca12/10.jpg'
import image11 from './pdf_apodaca12/11.jpg'
import image12 from './pdf_apodaca12/12.jpg'
import image13 from './pdf_apodaca12/13.jpg'
import image14 from './pdf_apodaca12/14.jpg'
import image15 from './pdf_apodaca12/15.jpg'
import image16 from './pdf_apodaca12/16.jpg'
import image17 from './pdf_apodaca12/17.jpg'
import image18 from './pdf_apodaca12/18.jpg'
import image19 from './pdf_apodaca12/19.jpg'
import image20 from './pdf_apodaca12/20.jpg'
import image21 from './pdf_apodaca12/21.jpg'
import image22 from './pdf_apodaca12/22.jpg'
import image23 from './pdf_apodaca12/23.jpg'
import image24 from './pdf_apodaca12/24.jpg'
import image25 from './pdf_apodaca12/25.jpg'
import image26 from './pdf_apodaca12/26.jpg'
import image27 from './pdf_apodaca12/27.jpg'
import image28 from './pdf_apodaca12/28.jpg'
import '../../css/homes/apartment.css'

const Apodaca12 = () => {
    const images = [
        image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25, image26, image27, image28
    ]
    return (
        <div className='apartment'>
            <h1>Apodaca12</h1>
            <section className='intro'>
                <i>
                    Our vision is to redefine the concept of hospitality, blending the allure of boutique accommodations with the personal touch of gifted hosts.Welcome to a sanctuary where tranquility meets inspiration."

                </i>
            </section>
            <HomesDepliant
                images={images}
                depliantPDF={depliant}
            />
        </div>
    )
}

export default Apodaca12