import React from 'react'
import HomesDepliant from './HomesDepliant'
import depliant from './pdf_suiteFlorencia/SuiteFlorencia-depliant.pdf'
import image1 from './pdf_suiteFlorencia/01.jpg'
import image2 from './pdf_suiteFlorencia/02.jpg'
import image3 from './pdf_suiteFlorencia/03.jpg'
import image4 from './pdf_suiteFlorencia/04.jpg'
import image5 from './pdf_suiteFlorencia/05.jpg'
import image6 from './pdf_suiteFlorencia/06.jpg'
import image7 from './pdf_suiteFlorencia/07.jpg'
import image8 from './pdf_suiteFlorencia/08.jpg'
import image9 from './pdf_suiteFlorencia/09.jpg'
import image10 from './pdf_suiteFlorencia/10.jpg'
import image11 from './pdf_suiteFlorencia/11.jpg'
import image12 from './pdf_suiteFlorencia/12.jpg'
import image13 from './pdf_suiteFlorencia/13.jpg'
import image14 from './pdf_suiteFlorencia/14.jpg'
import image15 from './pdf_suiteFlorencia/15.jpg'
import image16 from './pdf_suiteFlorencia/16.jpg'
import image17 from './pdf_suiteFlorencia/17.jpg'
import image18 from './pdf_suiteFlorencia/18.jpg'
import image19 from './pdf_suiteFlorencia/19.jpg'
import image20 from './pdf_suiteFlorencia/20.jpg'
import image21 from './pdf_suiteFlorencia/21.jpg'
import image22 from './pdf_suiteFlorencia/22.jpg'
import image23 from './pdf_suiteFlorencia/23.jpg'
import image24 from './pdf_suiteFlorencia/24.jpg'
import image25 from './pdf_suiteFlorencia/25.jpg'
import image26 from './pdf_suiteFlorencia/26.jpg'
import image27 from './pdf_suiteFlorencia/27.jpg'
import image28 from './pdf_suiteFlorencia/28.jpg'
import image29 from './pdf_suiteFlorencia/29.jpg'
import image30 from './pdf_suiteFlorencia/30.jpg'
import image31 from './pdf_suiteFlorencia/31.jpg'
import image32 from './pdf_suiteFlorencia/32.jpg'
import image33 from './pdf_suiteFlorencia/33.jpg'
import image34 from './pdf_suiteFlorencia/34.jpg'
import image35 from './pdf_suiteFlorencia/35.jpg'
import image36 from './pdf_suiteFlorencia/36.jpg'
import image37 from './pdf_suiteFlorencia/37.jpg'
import image38 from './pdf_suiteFlorencia/38.jpg'
import image39 from './pdf_suiteFlorencia/39.jpg'
import image40 from './pdf_suiteFlorencia/40.jpg'
import image41 from './pdf_suiteFlorencia/41.jpg'
import image42 from './pdf_suiteFlorencia/42.jpg'
import image43 from './pdf_suiteFlorencia/43.jpg'
import image44 from './pdf_suiteFlorencia/44.jpg'

import '../../css/homes/apartment.css'

const SuiteFlorencia = () => {
    const images = [
        image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25, image26, image27, image28,
        image29, image30, image31, image32, image33, image34, image35, image36, image37, image38, image39, image40, image41, image42, image43, image44
    ]
    return (
        <div className='apartment'>
            <h1>SuiteFlorencia</h1>
            <section className='intro'>
                {/*                 <p>
                    Boutique Gems & Hospitable Artisans ...  That's our essence!
                    Discover the essence of hospitality fused with artistry in our collection of boutique properties. Each one a hidden gem, nestled in prime locations and steeped in rich history.
                </p>
                <i>
                    Our hallmark? Exceptional hosts whose warmth and talent elevate your experience to unforgettable heights.
                </i>
                <p>
                    Step into a world where hosts aren't just guides but artisans of hospitality. Whether sharing stories of the past, showcasing local craftsmanship, or curating bespoke experiences, our hosts embody the soul of their surroundings.
                </p> */}
                <i>
                    Our vision is to redefine the concept of hospitality, blending the allure of boutique accommodations with the personal touch of gifted hosts.Welcome to a sanctuary where tranquility meets inspiration."

                </i>
            </section>
            <HomesDepliant
                images={images}
                depliantPDF={depliant}
            />
        </div>
    )
}

export default SuiteFlorencia