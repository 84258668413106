import jwtDecode from "jwt-decode";

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem("jwtToken", token);
  } else {
    localStorage.removeItem("jwtToken");
  }
};

// Function to check if a user is authenticated
export const isAuthenticated = () => {
  const token = localStorage.getItem("jwtToken");
  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Divide by 1000 to get seconds
    return decodedToken.exp > currentTime;
  }
  return false;
};