import React from 'react'
import backgroundImage from '../../assets/Homes/landing_page.jpeg'
import arrow from '../../assets/icons/right-arrow.png'
import suiteFlorencia from '../../assets/Homes/suiteFlorencia/suiteFlorencia_main.jpeg'
import suiteFlorencia_logo from '../../assets/Homes/suiteFlorencia/suiteFlorencia_logo.png'
import apodaca12 from '../../assets/Homes/apodaca12/apodaca_main.jpeg'
import apodacaLogo from '../../assets/Homes/apodaca12/apodaca_logo.svg'
import '../../css/homes/homes.css'
import { Link } from 'react-router-dom'

const Homes = () => {
    return (
        <div className='homes'>
            <section>
                <div className="backgroundText">
                    <h1>Cuba Luxury Homes</h1>
                    <h2>Where quiet Luxury Meets Personality: <br />Exquisite Properties, Exceptional Hosts</h2>
                </div>
                <a href="#houses">
                    <img className='arrow' src={arrow} alt="arrow icon" />
                </a>
                <img
                    className="backgroundImage"
                    src={backgroundImage}
                    alt="Comodino con specchio e fiori"
                />
            </section>

            <section className='intro'>
                <p>
                    <i>Boutique Gems & Hospitable Artisans ...  That's our essence!</i>
                </p>
                <p>
                    <i>Discover the essence of hospitality fused with artistry in our collection of boutique properties. Each one a hidden gem, nestled in prime locations and steeped in rich history.</i>
                </p>
                <p>
                    <i>Our hallmark? Exceptional hosts whose warmth and talent elevate your experience to unforgettable heights.</i>
                </p>
                <p>
                    <i>Step into a world where hosts aren't just guides but artisans of hospitality. Whether sharing stories of the past, showcasing local craftsmanship, or curating bespoke experiences, our hosts embody the soul of their surroundings.</i>
                </p>
                <p>
                    <i>Our vision is to redefine the concept of hospitality, blending the allure of boutique accommodations with the personal touch of gifted hosts.Welcome to a sanctuary where tranquility meets inspiration."
                    </i>
                </p>
            </section>

            <main>
                <h2 id="houses">Cuba Luxury Homes‘ Selection</h2>
                <div className="homesContainer">

                    <div className="home">
                        <div className="home__img">
                            <img src={suiteFlorencia} alt="suite florencia" />
                        </div>
                        <div className="home__text">
                            <div className="home__text__title">
                                <h3>Suite Florencia Boutique. Santa Clara.</h3>
                                <img
                                    className="suiteFlorencia_logo"
                                    src={suiteFlorencia_logo}
                                    alt="suite florencia logo"
                                />
                            </div>
                            <img src={suiteFlorencia} alt="suite florencia" />
                            <p>
                                Suite Florencia, a charming colonial-style haven nestled in the heart of Santa Clara city. Originally built in 1930 and meticulously refurbished in 2016, this boutique property exudes the enchantment and allure of colonial Cuba right from the moment you step through the white marble staircase entrance. Bright windows, high ceilings, and colorful original floors, that transports you to a bygone era.
                            </p>
                            <p>
                                This boutique property boasts four cozy and elegantly appointed bedrooms, each equipped with modern comforts to ensure your stay is both comfortable and memorable. Experience true relaxation in the Caribbean-inspired garden terrace, a lush green oasis where you can savor delectable breakfasts and dinners, sip on excellent drinks, or simply laze away in a hammock.
                            </p>
                            <Link to="/homes/Suite-Florencia">
                                Scopri di più
                            </Link>
                        </div>
                    </div>

                    <div className="home">
                        <div className="home__img">
                            <img src={apodaca12} alt="apodaca 12" />
                        </div>
                        <div className="home__text">
                            <div className="home__text__title">
                                <h3>Apodaca 12 - "Camaguey"</h3>
                                <img src={apodacaLogo} alt="apodaca12 logo" />
                            </div>
                            <img src={apodaca12} alt="apodaca 12" />
                            <div>
                                <i>
                                    Discover the charm of Apodaca 12 Hotel Boutique, a hidden gem tucked away amidst the winding streets of central Camaguey, a city steeped in historical and cultural heritage. Immerse yourself in the restored splendour of this early 1900s villa, where careful renovation has preserved its original architectural features and timeless character. Experience the ambiance of a historic family home, coupled with the luxury and amenities of a boutique hotel. Explore the essence of Camaguey  from here...
                                </i>
                            </div>
                            <p>
                                This Boutique offers five unique rooms, each with its own distinct charm and character, from cozy rooms to spacious suites, all reflecting the original architectural beauty of this early 1900s villa. A Tribute to Nature's Splendor ; as you step into Apodaca’s rooms, you'll be greeted by elegant décor meticulously chosen to echo the natural beauty of the island. From the colors to the art pieces, each element is a nod to this marvelous archipelago, offering you a peaceful retreat that harmonizes with nature. Cozy and Relaxing ,the rooms are perfect for solo travelers and couples alike. Large windows that fills the space with natural light, while also giving you a delightful view of the interior garden —a perfect setting to begin and end your day.
                            </p>
                            <Link to="/homes/apodaca-12">
                                Scopri di più
                            </Link>
                        </div>
                    </div>

                </div>
            </main >
        </div >
    )
}

export default Homes