import React from 'react'
import BasicPageStructure from '../basicPages/BasicPageStructure'
import '../../css/santaClara/santaClaraPage.css'

const SantaClaraWhatToSee = () => {
  const lang = localStorage.getItem("lang")
  const url = () => {
    switch (lang) {
      case "en":
        return "https://cuba-luxury-backend.onrender.com/page/64e38b3a9f3c523d9bd6e462";
      default: return "https://cuba-luxury-backend.onrender.com/page/64e38b3a9f3c523d9bd6e462"
    }
  }
  return (
    <div className="santaClaraPage">
      <BasicPageStructure url={url()} />
    </div>
  )
}

export default SantaClaraWhatToSee