import { Link } from 'react-router-dom'
import '../../css/tourCard.css'

const TourCard = ({ tour, name }) => {
  return (
    <Link to={`/tours/${tour._id}`} className="tourCard">
      <p>{tour.name}</p>
      <div className="tourCard__imgContainer">
        {tour.displayCover && <img src={tour.displayCover} alt="tour cover" />}
        <div className="filter" style={tour.displayCover ? null : { background: "linear-gradient(90deg, #CC9C29CF 0%, #95201ac9 100%)" }}></div>
      </div>
      <p className='tourCard__description'>
        {tour.displayDescription}
      </p>
      <div className="tourCard__discover">
        <p>Discover</p>
      </div>
    </Link>
  )
}

export default TourCard