import React from 'react'
import '../css/footer.css'

import phoneIcon from '../assets/icons/phone.png'
import mailIcon from '../assets/icons/email.png'
//import instagramIcon from '../assets/icons/instagram.png'
import facebookIcon from '../assets/icons/facebook.png'
//import linkedinIcon from '../assets/icons/linkedin.png'

function Footer() {
    return (
        <div className='Footer'>
            <div className="Footer__row"></div>

            <div className="Footer__container">
                <h3>CONTACT US</h3>

                <div className="Footer__container__firstRow">
                    <div className="item phone">
                        <img src={phoneIcon} alt="phone icon" className="icon" />
                        <a href="tel:+39 3926211584">+39 3926211584</a>
                    </div>
                    <div className="item mail">
                        <img src={mailIcon} alt="mail icon" className="icon" />
                        <a href="mailto:sales@cubaluxuryholidays.com">sales@cubaluxuryholidays.com</a>
                    </div>
                </div>

                <div className="Footer__container__secondRow">
                    {/* <div className="item">
                    <img src={instagramIcon} alt="instagram icon" className='icon'/>
                    <a href="/" target="_blank" rel="noreferrer">@instagram</a>
                </div> */}

                    <div className="item">
                        <img src={facebookIcon} alt="facebook icon" className='icon' />
                        <a href="https://www.facebook.com/profile.php?id=100087476622496" target="_blank" rel="noreferrer">@Cuba Luxury Holidays</a>
                    </div>

                    {/* <div className="item">
                    <img src={linkedinIcon} alt="linkedin icon" className='icon'/>
                    <a href="/" target="_blank" rel="noreferrer">@linkedin</a>
                </div> */}
                </div>
            </div>
        </div>
    )
}

export default Footer