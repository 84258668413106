import React from 'react'
import BasicPageStructure from './BasicPageStructure'
import '../../css/aboutCuba.css'

const AboutCuba = () => {
  const lang = localStorage.getItem("lang")
  const url = () => {
    switch (lang) {
      case "en":
        return "https://cuba-luxury-backend.onrender.com/page/64e38abf9f3c523d9bd6e461";
      default: return "https://cuba-luxury-backend.onrender.com/page/64e38abf9f3c523d9bd6e461"
    }
  }

  return (
    <div className='aboutCuba'>
      <h1>ABOUT CUBA</h1>
      <BasicPageStructure url={url()} />
    </div>
  )
}

export default AboutCuba