import React, { useRef, useEffect, useState } from 'react';
import { PageFlip } from 'page-flip';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '../../css/homes/homesDepliant.css';
import '@react-pdf-viewer/core/lib/styles/index.css';


const HomesDepliant = ({ images, depliantPDF }) => {
    const bookContainer = useRef(null);
    const [isReady, setIsReady] = useState(false);
    const [pageFlipInstance, setPageFlipInstance] = useState(null);
    const [isCoverShown, setIsCoverShown] = useState(true);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);

    useEffect(() => {
        console.log('load')
        if (bookContainer.current) {
            // Create a new PageFlip object
            const instance = new PageFlip(bookContainer.current, {
                width: 800,
                height: 800,
                minWidth: 600,
                size: 'stretch',
                showCover: true,
                flippingTime: 200,
            })

            // Load images when component mounts
            instance.loadFromImages(images);

            setPageFlipInstance(instance);

            // Set the component as ready
            setIsReady(true);
        }

        // Cleanup function
        return () => {
            if (pageFlipInstance) {
                pageFlipInstance.destroy();
                setIsReady(false);
            }
        };
    }, []);

    useEffect(() => {
        console.log("load")
        if (pageFlipInstance && isReady) {
            // Wait for the flipbook to be ready before showing the cover
            pageFlipInstance.on('ready', () => {
                setIsCoverShown(true);
            });

            // Listen for page flip events
            pageFlipInstance.on('flip', (event) => {
                setCurrentPageIndex(event.data);
            });
        }
    }, [isReady]);

    const flipNextPage = () => {
        if (isReady && pageFlipInstance) {
            pageFlipInstance.flipNext();
        }
    };

    const flipPrevPage = () => {
        if (isReady && pageFlipInstance) {
            pageFlipInstance.flipPrev();
        }
    };

    return (
        <>
            <div className='homesDepliant desktop'>
                {isCoverShown && (
                    <div className="flipbookContainer" ref={bookContainer} style={{ display: 'none' }} />
                )}
                <div className="flipbookContainer" style={{ display: isCoverShown ? 'block' : 'none' }} ref={bookContainer} />
                <div className="buttonContainer">
                    <button
                        onClick={flipPrevPage}
                        disabled={!isReady || currentPageIndex === 0}
                    >
                        Prev page
                    </button>
                    <button
                        onClick={flipNextPage}
                        disabled={!isReady || currentPageIndex === images.length - 1}
                    >
                        Next page
                    </button>
                </div>
                <a href={depliantPDF} target='_blank' rel="noreferrer">Download the brochure</a>
            </div>
            <div className="homesDepliant mobile">
                <div className="pdfContainer">
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer
                            fileUrl={depliantPDF}
                            theme={{
                                theme: "dark"
                            }}
                        />
                    </Worker>
                </div>
                <div className="text">
                    <p>Browse our online brochure and discover everything about us!</p>
                    <a
                        href={depliantPDF}
                        target='_blank'
                        rel="noreferrer"
                    >
                        Browse the brochure
                    </a>
                </div>
            </div>
        </>
    );
};

export default HomesDepliant;
