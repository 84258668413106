import React, { useState, useEffect } from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import '../css/imageSlider.css';

const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [loaded, setLoaded] = useState([])
  const length = slides.length;

  const nextSlide = () => {
    setCurrent((current + 1) % length);
  };

  const prevSlide = () => {
    setCurrent((current + length - 1) % length);
  };

  useEffect(() => {
    if (loaded.includes(current)) {
      const interval = setInterval(() => {
        nextSlide();
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [current, loaded]);


  if (!Array.isArray(slides) || length <= 0) {
    return null;
  }

  return (
    <section className='imageSlider'>
      <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
      {slides.map((slide, index) => {
        return (
          <div
            className={index === current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === current &&
              <>
                <img
                  src={slide.image}
                  className='image'
                  style={!loaded.includes(index) ? { display: "none" } : { display: "block" }}
                  onLoad={() => { setLoaded([...loaded, index]); }}
                  alt=""
                />
                {!loaded.includes(index) && <div className='skeleton-container'>
                  <div className="skeleton"></div>
                  <div className="spinner"></div>
                </div>}
              </>
            }
          </div>
        );
      })}
      <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />
    </section>
  );
};

export default ImageSlider;