import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isAuthenticated } from './Auth';
import imageCompression from 'browser-image-compression';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Loading from '../Loading';
import arrow from '../../assets/quotes/right-arrow.png';
import '../../css/admin/quotesEditor.css';

const PagesEditor = () => {
  const [quote, setQuote] = useState(null);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageAdd, setImageAdd] = useState(null);
  const [selectedType, setSelectedType] = useState('');
  const [newImages, setNewImages] = useState([]);
  const [success, setSuccess] = useState(false);
  const [lang, setLang] = useState('');
  const { id } = useParams();

  if (!isAuthenticated()) {
    window.location.href = '/login';
  }

  useEffect(() => {
    setLoading(true)
    fetch(`https://cuba-luxury-backend.onrender.com/page/${id}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error()
        }
      })
      .then((data) => {
        setQuote(data);
        setLang(data.language)
      })
      .catch((err) => {
        setError('Error, impossibile caricare la pagina')
      })
      .finally(() => {
        setLoading(false)
      });
  }, [id]);

  const scrollBottom = () => {
    document.getElementById('bottom').scrollIntoView({ behavior: 'smooth' });
  };

  const scrollTop = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  const handleEdit = (selected) => {
    setSelectedQuote(selected);
  };

  const handleChange = (e, index) => {
    if (index === -1) setQuote({ ...quote, customerName: e.target.value });
    else if (index === -2) setQuote({ ...quote, name: e.target.value });
    else if (index === -3) setQuote({ ...quote, display: !quote.display })
    else if (index === -4) setQuote({ ...quote, displayDescription: e.target.value })
    else if (e.target.name === 'title1' || e.target.name === 'title2') {
      let items = quote.items;
      items[index].title[e.target.name] = e.target.value;
      setQuote({ ...quote, items });
    } else {
      let items = quote.items;
      items[index][e.target.name] = e.target.value;
      setQuote({ ...quote, items });
    }
  };

  const handleRemove = (e, index) => {
    e.preventDefault();
    let items = quote.items;
    items.splice(index, 1);
    setQuote({ ...quote, items });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await fetch(`https://cuba-luxury-backend.onrender.com/page/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
      },
      body: JSON.stringify(quote),
    });
    if (res.ok) {
      setSuccess("Page updated successfully")
      window.location.href = `/admin/pagesView`;
    } else {
      setSuccess("Error, page not updated")
    }
    setLoading(false);
  };

  const handleImageRemove = (index, imgIndex) => {
    let items = quote.items;
    items[index].slider.splice(imgIndex, 1);
    if (items[index].slider.length === 0) items.splice(index, 1);
    setQuote({ ...quote, items });
  };

  const handleImageLoad = async (e, index) => {
    setLoading(true);
    const file = e.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 900,
    }

    try {
      const compressedFile = await imageCompression(file, options)
      const formData = new FormData();
      formData.append('image', compressedFile);

      const response = await fetch('https://cuba-luxury-backend.onrender.com/imgUpload', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')} `,
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Image upload failed');
      }
      const imageUrl = await response.json();
      const { url } = imageUrl;
      let items = quote.items;
      items[index].slider.push({ image: url });
      setQuote({ ...quote, items });
      setError('');

    } catch (error) {
      setError('Error uploading image');
    } finally {
      setLoading(false);
    }
  };

  const handleNewGalleryImage = async (e) => {
    setLoading(true);
    const file = e.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 900,
    }

    try {
      const compressedFile = await imageCompression(file, options)
      const formData = new FormData();
      formData.append('image', compressedFile);

      const response = await fetch('https://cuba-luxury-backend.onrender.com/imgUpload', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')} `,
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Image upload failed');
      }
      const imageUrl = await response.json();
      const { url } = imageUrl;
      setNewImages([...newImages, { image: url }]);
      setError('');
    } catch (error) {
      setError('Error uploading image');
    }
    setLoading(false);
  };


  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(quote.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setQuote({ ...quote, items });
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value)
  }

  const handleInsert = (e) => {
    e.preventDefault();
    if (selectedType) {
      let items = quote.items;

      if (selectedType === "title" && (e.target[0].value || e.target[1].value)) {
        items.push({ slider: [], title: { title1: e.target[0].value, title2: e.target[1].value }, size: e.target[2].value })
        setQuote({ ...quote, items });
        setSelectedType('')
      } else if (selectedType === "slider" && newImages.length > 0) {
        items.push({ slider: newImages })
        setNewImages([])
        setQuote({ ...quote, items });
        setSelectedType('')
      } else if (selectedType === "title3" || selectedType === "text") {
        items.push({ slider: [], [selectedType]: e.target[0].value, size: e.target[1].value })
        setQuote({ ...quote, items });
        setSelectedType('')
      } else if (selectedType === "interruption") {
        items.push({ slider: [], interruption: true })
        setQuote({ ...quote, items });
        setSelectedType('')
      }
    }
  }

  return (
    <div className="quotesEditor">
      {loading && <Loading />}
      <div className="scroll">
        <button className="scroll__bottom" onClick={scrollTop}>
          <img src={arrow} alt="arrow" />
        </button>
        <button className="scroll__top" onClick={scrollBottom}>
          <img src={arrow} alt="arrow" />
        </button>
      </div>
      <h1>Page Editor</h1>
      {error && <p className="error">{error}</p>}
      {quote && (
        <>
          <div className="quotesEditor__addItem">
            <label>
              Item Type:
              <select name="lang" value={selectedType} onChange={handleTypeChange}>
                <option value="">Select an item type</option>
                <option value="title">Title 1/2</option>
                <option value="title3">Title 3</option>
                <option value="text">Text</option>
                <option value="slider">Gallery</option>
                <option value="interruption">Interruption</option>
              </select>
            </label>
            <br />
            <form onSubmit={handleInsert}>
              {selectedType === 'title' && <>
                <input type="text" name="title1" placeholder="Title 1" />
                <input type="text" name="title2" placeholder="Title 2" />

                <select name="size" defaultValue="md">
                  <option value="" disabled>Select font</option>
                  <option value="sm">Small</option>
                  <option value="md">Medium</option>
                  <option value="lg">Large</option>
                </select>
              </>}
              {selectedType === 'title3' && <>
                <input type="text" name={selectedType} placeholder={selectedType} required />

                <select name="size" defaultValue="md">
                  <option value="" disabled>Select font</option>
                  <option value="sm">Small</option>
                  <option value="md">Medium</option>
                  <option value="lg">Large</option>
                </select>
              </>
              }
              {selectedType === "text" && <>
                <textarea type="text" name={selectedType} placeholder={selectedType} required />

                <select name="size" defaultValue="md">
                  <option value="" disabled>Select font</option>
                  <option value="sm">Small</option>
                  <option value="md">Medium</option>
                  <option value="lg">Large</option>
                </select>
              </>}
              {selectedType === "interruption" && <><p>Interruption</p><input name={selectedType} type="checkbox" readOnly checked hidden /></>}
              {selectedType === "slider" && <>
                <input type="file" onChange={handleNewGalleryImage} accept="image/*" />
                <div className="slider__preview">
                  {newImages.map((img, i) => (
                    <div className="gallery__image" key={i}>
                      <img className="gallery__container__image" src={img.image} alt="img" required />
                    </div>
                  ))}
                </div>
              </>}
              <button type="submit">Insert</button>
            </form>
          </div>

          <div className="row"></div>

          <div className="quotesEditor__lang">
            <p>Language: {lang}</p>
            <select value={lang} onChange={(e) => { setLang(e.target.value); setQuote({ ...quote, language: e.target.value }) }}>
              <option value="">Select a language</option>
              <option value="en">English</option>
              <option value="it">Italian</option>
            </select>
          </div>

          <div className="quotesEditor__name">
            <p>Quote's name: {quote.name}</p>
            {selectedQuote === -2 && (
              <>
                <input
                  type="text"
                  name="name"
                  placeholder={quote.name}
                  value={quote.name}
                  onChange={(e) => handleChange(e, -2)}
                />
                <button style={{ backgroundColor: '#2a4fbd' }} onClick={() => handleEdit(null)}>
                  Close
                </button>
              </>
            )}
            {selectedQuote !== -2 && (
              <div className="edit">
                <button style={{ backgroundColor: '#b75a2c' }} onClick={() => handleEdit(-2)}>
                  Edit
                </button>
              </div>
            )}
          </div>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="items">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {quote.items.map((item, index) => (
                    <Draggable key={index} draggableId={`item - ${index} `} index={index}>
                      {(provided) => (
                        <div
                          className="quotesEditor__item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {item.title && (
                            <>
                              {item.title.title1 && <p>Title 1: {item.title.title1}</p>}
                              {item.title.title2 && <p>Title 2: {item.title.title2}</p>}
                              <p>Font Size: {item.size}</p>
                              {selectedQuote === index && (
                                <>
                                  <input
                                    type="text"
                                    value={item.title.title1}
                                    name="title1"
                                    onChange={(e) => handleChange(e, index)}
                                  />

                                  <input
                                    type="text"
                                    value={item.title.title2}
                                    name="title2"
                                    onChange={(e) => handleChange(e, index)}
                                  />

                                  <select name="size" value={item.size} onChange={(e) => handleChange(e, index)}>
                                    <option value="" disabled>Select font</option>
                                    <option value="sm">Small</option>
                                    <option value="md">Medium</option>
                                    <option value="lg">Large</option>
                                  </select>

                                  <button
                                    style={{ backgroundColor: 'red' }}
                                    onClick={(e) => {
                                      handleRemove(e, index);
                                      handleEdit(null);
                                    }}
                                  >
                                    Remove
                                  </button>
                                  <button
                                    style={{ backgroundColor: '#2a4fbd' }}
                                    onClick={() => handleEdit(null)}
                                  >
                                    Close
                                  </button>
                                </>
                              )}
                            </>
                          )}
                          {item.title3 && (
                            <>
                              <p>Title 3: {item.title3}</p>
                              <p>Font Size: {item.size}</p>
                              {selectedQuote === index && (
                                <>
                                  <input
                                    type="text"
                                    value={item.title3}
                                    name="title3"
                                    placeholder={item.title3}
                                    onChange={(e) => handleChange(e, index)}
                                  />

                                  <select name="size" value={item.size} onChange={(e) => handleChange(e, index)}>
                                    <option value="" disabled>Select font</option>
                                    <option value="sm">Small</option>
                                    <option value="md">Medium</option>
                                    <option value="lg">Large</option>
                                  </select>

                                  <button
                                    style={{ backgroundColor: 'red' }}
                                    onClick={(e) => {
                                      handleRemove(e, index);
                                      handleEdit(null);
                                    }}
                                  >
                                    Remove
                                  </button>
                                  <button
                                    style={{ backgroundColor: '#2a4fbd' }}
                                    onClick={() => handleEdit(null)}
                                  >
                                    Close
                                  </button>
                                </>
                              )}
                            </>
                          )}
                          {item.text && (
                            <>
                              <p>Text: {item.text}</p>
                              <p>Font Size: {item.size}</p>
                              {selectedQuote === index && (
                                <>
                                  <textarea
                                    type="text"
                                    value={item.text}
                                    name="text"
                                    placeholder={item.text}
                                    onChange={(e) => handleChange(e, index)}
                                  />

                                  <select name="size" value={item.size} onChange={(e) => handleChange(e, index)}>
                                    <option value="" disabled>Select font</option>
                                    <option value="sm">Small</option>
                                    <option value="md">Medium</option>
                                    <option value="lg">Large</option>
                                  </select>

                                  <button
                                    style={{ backgroundColor: 'red' }}
                                    onClick={(e) => {
                                      handleRemove(e, index);
                                      handleEdit(null);
                                    }}
                                  >
                                    Remove
                                  </button>
                                  <button
                                    style={{ backgroundColor: '#2a4fbd' }}
                                    onClick={() => handleEdit(null)}
                                  >
                                    Close
                                  </button>
                                </>
                              )}
                            </>
                          )}

                          {item.interruption && (
                            <>
                              <p>Interruption</p>
                              {selectedQuote === index && (
                                <>
                                  <button
                                    style={{ backgroundColor: 'red' }}
                                    onClick={(e) => {
                                      handleRemove(e, index);
                                      handleEdit(null);
                                    }}
                                  >
                                    Remove
                                  </button>
                                  <button
                                    style={{ backgroundColor: '#2a4fbd' }}
                                    onClick={() => handleEdit(null)}
                                  >
                                    Close
                                  </button>
                                </>
                              )}
                            </>
                          )}
                          {item.slider.length > 0 && (
                            <>
                              <p>Gallery</p>
                              <div className="gallery__container" key={index}>
                                {item.slider.map((img, i) => (
                                  <div className="gallery__image" key={i}>
                                    <img className="gallery__container__image" src={img.image} alt="img" />
                                    {selectedQuote === index && (
                                      <button onClick={() => handleImageRemove(index, i)}>Remove</button>
                                    )}
                                  </div>
                                ))}
                              </div>
                              {imageAdd === index && (
                                <>
                                  <input
                                    type="file"
                                    onChange={(e) => handleImageLoad(e, index)}
                                    accept="image/*"
                                  />
                                </>
                              )}
                              {selectedQuote === index && (
                                <>
                                  {imageAdd !== index && (
                                    <button
                                      style={{ backgroundColor: 'green' }}
                                      onClick={() => {
                                        setImageAdd(index);
                                      }}
                                    >
                                      Add
                                    </button>
                                  )}

                                  <button
                                    style={{ backgroundColor: 'red' }}
                                    onClick={(e) => {
                                      handleRemove(e, index);
                                      handleEdit(null);
                                    }}
                                  >
                                    Remove gallery
                                  </button>

                                  <button
                                    style={{ backgroundColor: '#2a4fbd' }}
                                    onClick={() => {
                                      handleEdit(null);
                                      setImageAdd(null);
                                    }}
                                  >
                                    Close
                                  </button>
                                </>
                              )}
                            </>
                          )}
                          {!selectedQuote && (
                            <div className="drag-handle">
                              <div className="drag-icon"></div>
                            </div>
                          )}
                          {selectedQuote !== index && <button style={{ backgroundColor: "#b75a2c" }} onClick={() => handleEdit(index)}>Edit</button>}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {success && <p className="success">{success}</p>}
          <button
            style={{ padding: "10px 20px", border: "none", cursor: "pointer", backgroundColor: "#378626", borderRadius: "5px", color: "white", marginTop: "20px" }}
            onClick={handleSave}>
            Save All
          </button>
          <div id="bottom"></div>
        </>
      )}
    </div>
  );
};

export default PagesEditor;