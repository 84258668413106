import { useState, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import Loading from './Loading'

function QrRedirect() {
  const { id } = useParams()
  const [redirect, setRedirect] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    fetch(`https://cuba-luxury-backend.onrender.com/qr/${id}`)
      .then(res => {
        if (!res.ok) {
          throw Error;
        } else {
          return res.json()
        }
      })
      .then(data => {
        const redirectFullUrl = data.url
        const redirectRelativePath = new URL(redirectFullUrl).pathname
        setRedirect(redirectRelativePath)
      })
      .catch(() => setRedirect("/"))
      .finally(() => { setIsLoading(false) })
  }, [id])

  return (<>{
    isLoading ? <Loading /> : <Navigate to={redirect} />
  }</>
  )
}

export default QrRedirect