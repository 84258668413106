import SantaClaraCard from "../Cards/SantaClaraCard"
import { santaClaraPages } from "../../constants/santaClara"
import "../../css/santaClara/santaClara.css"

function SantaClara() {
  return (
    <div className="santaClara">
      <h1>Santa Clara</h1>
      <h3>Explore the following sections and find out everything about Santa Clara</h3>

      <div className="santaClara__sections">
        {santaClaraPages.map((page, index) => (
          <SantaClaraCard data={page} key={index} />
        ))}
      </div>
    </div>
  )
}

export default SantaClara