import restaurants from '../assets/santaClara/BestRestaurants/florencia/Group3.png'
import tours from '../assets/santaClara/tours/tour.jpg'
import nightLife from '../assets/santaClara/NightLife/valhalla/Group1.png'
import notToMiss from '../assets/santaClara/notToMiss/intro1.jpg'

export const santaClaraPages = [
  {
    title: "Not to miss",
    href: "/santa-clara-not-to-miss",
    text: "Explore Santa Clara's Hidden Treasures. You can't to Miss!",
    image: notToMiss,
    altImage: "suite forencia restaurant"
  },
  {
    title: "Tours",
    href: "/santa-clara-tours",
    text: "Embark on Unforgettable Tours in Santa Clara!",
    image: tours,
    altImage: "santa clara landscapes"
  },
  {
    title: "Night life",
    href: "/santa-clara-night-life",
    text: "Experience the Vibrant Nightlife of Santa Clara!",
    image: nightLife,
    altImage: "suite forencia restaurant"
  },
  {
    title: "Restaurants",
    href: "/santa-clara-restaurants",
    text: "Discover Santa Clara's Finest Dining Destinations!",
    image: restaurants,
    altImage: "suite forencia restaurant"
  }
];
