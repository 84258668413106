import { useState, useEffect } from 'react'
import TourCard from '../Cards/TourCard'
import Loading from '../Loading'
import '../../css/tours.css'

const Tours = () => {
  const [tours, setTours] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {
    setLoading(true)
    fetch('https://cuba-luxury-backend.onrender.com/tours')
      .then(res => {
        if (!res.ok) {
          throw Error;
        } else {
          return res.json()
        }
      })
      .then(data => {
        if (data)
          setTours(data)
        else
          throw Error;
      })
      .catch(err => {
        setError("Can't load tours")
      })
      .finally(() => { setLoading(false) });
  }, [])

  return (
    <div className='tours'>
      {loading && <Loading />}
      <h1>Tours</h1>
      {tours.length > 0 ?
        <div className="tours__container">
          {tours.map(tour => <TourCard key={tour._id} tour={tour} />)}
        </div> :
        <p>Loading...</p>
      }
      {error && <div className='error'>{error}</div>}
    </div>
  )
}

export default Tours