import React from "react";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import "./css/app.css"

/* BAIC PAGES */
import Home from "./Components/Home"
import Footer from "./Components/Footer"
import Menu from "./Components/Menu"
import OurTeam from "./Components/basicPages/OurTeam";
import AboutCuba from "./Components/basicPages/AboutCuba";
import AboutUs from "./Components/basicPages/AboutUs";
import Contacts from "./Components/basicPages/Contacts";

/* SANTA CLARA */
import SantaClara from "./Components/santaClara/SantaClara";
import SantaClaraNotToMiss from "./Components/santaClara/SantaClara-what-to-see";
import SantaClaraBestRestaurants from "./Components/santaClara/SantaClara-best-restaurants";
import SantaClaraNightlife from "./Components/santaClara/SantaClara-nightlife";
import SantaClaraTours from "./Components/santaClara/SantaClara-tours";

/* TOURS */
import Tours from "./Components/basicPages/Tours";

/* QUOTE */
import Quote from "./Components/Quote";

/* HOMES */
import HomesMenu from "./Components/homes/HomesMenu";
import Homes from "./Components/homes/Homes";
import HomesDepliant from "./Components/homes/HomesDepliant";
import SuiteFlorencia from "./Components/homes/SuiteFlorencia";
import Apodaca12 from "./Components/homes/Apodaca12";

/* ADMIN */
import LoginPage from "./Components/admin/LoginPage";
import AdminMenu from "./Components/admin/AdminMenu";
import QuotesGenerator from "./Components/admin/QuotesGenerator";
import QuotesView from "./Components/admin/QuotesView";
import QuotesEditor from "./Components/admin/QuotesEditor";
import LangPrompt from "./Components/admin/LangPrompt";
import PageView from "./Components/admin/PageView";
import PagesEditor from "./Components/admin/PagesEditor";
import PagesGenerator from "./Components/admin/PagesGenerator";
import Page from "./Components/admin/Page"

/* PAGE NOT FOUND */
import NotFound from "./Components/basicPages/NotFound";

/* QR */
import QrRedirect from "./Components/QrRedirect";
import QrView from "./Components/admin/QrView";

const useReactPath = () => {
  const [path, setPath] = React.useState(window.location.pathname);
  const listenToPopstate = () => {
    const winPath = window.location.pathname;
    setPath(winPath);
  };
  React.useEffect(() => {
    window.addEventListener("popstate", listenToPopstate);
    return () => {
      window.removeEventListener("popstate", listenToPopstate);
    };
  }, []);
  return path;
};

function App() {
  const [prompt, setPrompt] = React.useState(false)
  const path = useReactPath();
  React.useEffect(() => {
    console.log("change")
  }, [path]);
  /* const setLang = () => {
    let lang = localStorage.getItem("lang")
    if (!lang)
      setPrompt(true)
  }

  useEffect(() => {
    setLang()
    if (prompt)
      document.documentElement.style.overflow = 'hidden';
    else
      document.documentElement.style.overflow = '';
  }, [prompt]) */

  return (
    <>
      {prompt && <LangPrompt />}
      <div className="background"></div>
      <div className="App">
        <Router>
          {window.location.href.includes("homes") ? <HomesMenu /> : <Menu />}
          <Routes>
            {/* BASIC PAGES */}
            <Route path="/" element={<Home />} exact />
            <Route path="/our-team" element={<OurTeam />} exact />
            <Route path="/why-cuba" element={<AboutCuba />} exact />
            <Route path="/contacts" element={<Contacts />} exact />
            <Route path="/about-us" element={<AboutUs />} exact />

            {/* SANTA CLARA */}
            <Route path="/santa-clara" element={<SantaClara />} exact />
            <Route path="/santa-clara-not-to-miss" element={<SantaClaraNotToMiss />} exact />
            <Route path="/santa-clara-restaurants" element={<SantaClaraBestRestaurants />} exact />
            <Route path="/santa-clara-tours" element={<SantaClaraTours />} exact />
            <Route path="/santa-clara-night-life" element={<SantaClaraNightlife />} exact />

            {/* TOURS */}
            <Route path="/tours" element={<Tours />} exact />
            <Route path="/tours/:id" element={<Quote />} exact />

            {/* QUOTE */}
            <Route path="/quote/:id" element={<Quote />} exact />

            {/* HOMES */}
            <Route path="/homes" element={<Homes />} exact />
            <Route path="/homes/depliant" element={<HomesDepliant />} exact />
            <Route path="/homes/Suite-Florencia" element={<SuiteFlorencia />} exact />
            <Route path="/homes/Apodaca-12" element={<Apodaca12 />} exact />

            {/* QR */}
            <Route path="/qr/:id" element={<QrRedirect />} exact />


            {/* ADMIN */}
            <Route path="/admin/select-page" element={< AdminMenu />} exact />
            <Route path="/admin/quotesGenerator" element={<QuotesGenerator />} exact />
            <Route path="/admin/quotesView" element={<QuotesView />} exact />
            <Route path="/admin/quotesEditor/:id" element={<QuotesEditor />} exact />
            <Route path="/admin/pagesEditor/:id" element={<PagesEditor />} exact />
            <Route path="/login" element={<LoginPage />} exact />
            <Route path="/admin/pagesView" element={<PageView />} exact />
            <Route path="/admin/pagesEditor" element={<PagesGenerator />} exact />
            <Route path="/admin/page/:id" element={<Page />} exact />
            <Route path="/admin/qrView" element={<QrView />} exact />

            {/* NOT FOUND */}
            <Route path="*" element={<NotFound />} />
          </Routes>

          <Footer />
        </Router>
      </div>
    </>
  );
}

/* TODO: 
  cross site
  handle expired token
*/

export default App;