import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ImageSlider from './ImageSlider'
import Loading from './Loading'
import '../css/quote.css'
import LinkAdder from './LinkAdder'


const Quote = () => {
  const [quote, setQuote] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState()
  const { id } = useParams();

  useEffect(() => {
    fetch(`https://cuba-luxury-backend.onrender.com/quote/${id}`)
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          setError(true)
        }
      })
      .then(data => {
        setQuote(data)
        setIsLoading(false)
      })
      .catch(err => {
        setError(true)
        setIsLoading(false)
      })
  }, [id])

  return (
    <div className='quote'>
      <div className="quote__download noPrint">
        <p>Download your quote and take it with you on your journey!</p>
        <button onClick={() => { window.print() }} className="noPrint">Download</button>
      </div>
      {isLoading && <Loading message={"Loading your quote..."} />}
      {error ? <h1 className="error">Can't find your quote,<br />please reload the page</h1> :
        <div className="quote">
          {quote && quote.customerName && <h1 className="quote__customerName">Hi {quote.customerName}, explore the journey we created for you!</h1>}
          {quote.items && quote.items.map((item, key) =>
            <section className='quote__section' key={key}>
              {/* TITLE1 AND TITLE 2 */}
              {item.title && <div className={'quote__section__title ' + item.size}>
                {item.title.title1 && <h2>{<LinkAdder text={item.title.title1} />}</h2>}
                {item.title.title2 && <h3>{<LinkAdder text={item.title.title2} />}</h3>}
              </div>}
              {/* TITLE 3 */}
              {item.title3 && <div className={'quote__section__title ' + item.size}>
                <h2 className='title3'>{<LinkAdder text={item.title3} />}</h2>
              </div>}
              {/* TESTO */}
              {item.text && <div className={'quote__section__text ' + item.size}>
                <p className='text'><LinkAdder text={item.text} /></p>
              </div>}
              {/* SLIDER */}
              {item.slider.length > 0 && <div className='quote__section__slider noPrint'>
                {item.slider.length !== 1 ?
                  <ImageSlider slides={item.slider} /> :
                  <img className='slider__image' src={item.slider[0].image} alt="" />
                }
              </div>}
              {/* INTERUPTION */}
              {item.interruption && <div className='quote__section__interruption'>
                <div className="row noPrint"></div>
              </div>}
            </section>
          )}
        </div>
      }
    </div >
  )
}

export default Quote