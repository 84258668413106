import React from 'react'
import '../../css/contacts.css'
import businnesCard from '../../assets/businessCard.png'

function Contacts() {
  return (
    <div className='Contacts'>
      <h1>CONTACTS</h1>
      <p>Do not hesitate to contact us by phone or mail for questions, problems or reservations </p>

      <div className="Contacts__card">
        <div className="Contacts__card__upper">
          <p className="name">CUBA LUXURY HOLIDAYS</p>
          <p className="role">Fascinating & Unique</p>
        </div>

        <div className="Contacts__card__bottom">
          <div className="item">
            <p>Tel:</p>
            <a href="tel:+39 3926211584" style={{ marginLeft: "15px" }}>+39  392 6211584</a>
          </div>

          <div className="item">
            <p>Mail:</p>
            <a href="mailto:sales@cubaluxuryholidays.com">sales@cubaluxuryholidays.com</a>
          </div>

          <div className="item">
            <p>Web:</p>
            <a href="https://www.cubaluxuryholidays.com">www.cubaluxuryholidays.com</a>
          </div>
        </div>
      </div>

      <p>Do you want to share or keep our businnes card with you to contact us esaily?</p>
      <a href={businnesCard} download="CUBA LUXURY HOLIDAY - BUSINNES CARD" className='download'>Download our businnes card</a>
      <div className="spaceFiller"></div>
    </div>
  )
}

export default Contacts