import React from 'react'
import OurTeamCard from '../Cards/OurTeamCard'
import { members } from '../../constants/team'
import '../../css/ourTeam.css'

function OurTeam() {
  return (
    <div className='OurTeam'>
      <div className="OurTeam__intro">
        <h2><span>Cuba Luxury Holidays</span> is based on Como Lake, Italy. <br /> Surrounded by beauty, we <span>share beauty!</span></h2>
      </div>

      <div className="OurTeam__ourTeam">
        <h3>OUR TEAM</h3>
        <div className="OurTeam__ourTeam__cardContainer">
          {members.map((member, index) => <OurTeamCard key={index} data={member} />)}
        </div>
      </div>
    </div>
  )
}

export default OurTeam