import React from 'react'

const LinkAdder = ({ text }) => {
    const regex = /(#\[.+?\|.+?])/gm
    const out = text.split(regex).map((item, key) => {
        if (item.match(regex)) {
            const link = item.split('|')
            link[0] = link[0].replace('#[', '')
            link[1] = link[1].replace(']', '')
            return <a href={link[1]} key={key}>{link[0]}</a>
        }

        return item
    })

    return (
        <>
            {out}
        </>
    )
}

export default LinkAdder