import React from 'react'
import BasicPageStructure from '../basicPages/BasicPageStructure'
import { isAuthenticated } from './Auth'
import { useParams } from 'react-router-dom'

const Page = () => {
  if (!isAuthenticated()) {
    window.location.href = '/login';
  }

  const { id } = useParams();
  return (
    <BasicPageStructure url={'https://cuba-luxury-backend.onrender.com/page/' + id} />
  )
}

export default Page