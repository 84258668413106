
const AboutUsCard = ({ review }) => {
  return (
    <div className="AboutUs__review">
      <p>{review.text}</p>
      <div className="articleInfo">
        <div className="articleInfo__container clearfix">
          <img src={review.logo} alt={review.logoAlt} className='logo' />
          <p className='name'><span>{review.reviewer},</span> {review.date}</p>
        </div>
      </div>
      <img src={review.image} alt={review.altImage} className='picture' />
    </div>
  )
}

export default AboutUsCard