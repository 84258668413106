import React from 'react'
import '../css/home.css'
import HomeBg from '../assets/Home/background.webp'

function Home() {
  return (
    <div className="Home">
        <div className="Home__bgContainer">
            <img src={HomeBg} alt="Sfondo home cuba luxury holidays"/>
            <div className="filter"></div>

            <div className="Home__bgText">
                <h1>CUBA LUXURY HOLIDAYS</h1>
                <h2>"This is the most beautiful land that human eyes have ever seen“</h2>
                <p>Christopher Columbus, October 1492</p>
            </div>
        </div>
    </div>
  )
}

export default Home