import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import '../../../css/admin/card/customPageItemList.css'

const CustomPageItemList = ({ quote, handleRemoveItem, handleDragEnd }) => {
    const items = quote.items

    return (
        <div className="item-list">
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='items'>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {items.map((item, index) => (
                                <Draggable key={index} draggableId={`item - ${index} `} index={index}>
                                    {(provided) => (
                                        <div
                                            className="item"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {(item.title.title1 !== "" || item.title.title2 !== "") && (
                                                <>
                                                    {item.title.title1 && <p>Title 1: {item.title.title1}</p>}
                                                    {item.title.title2 && <p>Title 2: {item.title.title2}</p>}
                                                    <p>Font Size: {item.size}</p>
                                                </>
                                            )}
                                            {item.title3 !== "" && <>
                                                <p>Title 3: {item.title3}</p>
                                                <p>Font Size: {item.size}</p>
                                            </>}
                                            {item.text !== "" && <>
                                                <p>Text: {item.text}</p>
                                                <p>Font Size: {item.size}</p>
                                            </>}
                                            {item.slider.length > 0 && (
                                                <div>
                                                    <p>Slider Images:</p>
                                                    {item.slider.map((image, i) => (
                                                        <img src={image.image} alt={`Slider ${i + 1}`} style={{ width: '200px' }} key={i} />
                                                    ))}
                                                </div>
                                            )}
                                            {item.interruption && <p>Interruption</p>}
                                            <button className='removeItem' type="button" onClick={() => handleRemoveItem(index)}>
                                                Remove Item
                                            </button>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}

export default CustomPageItemList