import React, { useState } from "react";
import { setAuthToken } from "./Auth";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    setError("");
    setIsLoading(true);

    try {
      const response = await fetch("https://cuba-luxury-backend.onrender.com/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error("Invalid credentials");
      }

      const { token } = await response.json();
      setAuthToken(token);

      window.location.href = "/admin/select-page";
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Login Page</h1>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => { setUsername(e.target.value); setError("") }}
        style={styles.input}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => { setPassword(e.target.value); setError("") }}
        style={styles.input}
      />
      <button onClick={handleLogin} style={styles.button} disabled={isLoading}>
        {isLoading ? "Loading..." : "Login"}
      </button>
      {error && <p style={styles.errorMessage}>{error}</p>}
    </div>
  );
};

export default LoginPage;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#dbccb2",
  },
  heading: {
    fontSize: "24px",
    marginBottom: "16px",
  },
  input: {
    width: "200px",
    height: "40px",
    marginBottom: "8px",
    padding: "4px",
    fontSize: "16px",
  },
  button: {
    width: "100px",
    height: "40px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
  },
  errorMessage: {
    color: "red",
    marginTop: "8px",
  },
};
