import React from 'react'
import '../../css/notFound.css'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div className='NotFound'>
      <h1>PAGE NOT FOUND (404)</h1>
      <Link to="/">Click here to come back to home</Link>
    </div>
  )
}

export default NotFound