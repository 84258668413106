import React, { useState } from 'react';
import { isAuthenticated } from './Auth';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import imageCompression from 'browser-image-compression';
import arrow from '../../assets/quotes/right-arrow.png';
import '../../css/admin/quotesGenerator.css';
import Loading from '../Loading';

function PagesGenerator() {
  const [name, setName] = useState('');
  const [lang, setLang] = useState('');
  const [items, setItems] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [title1, setTitle1] = useState('');
  const [title2, setTitle2] = useState('');
  const [title3, setTitle3] = useState('');
  const [text, setText] = useState('');
  const [size, setSize] = useState('md');
  const [sliderImages, setSliderImages] = useState([]);
  const [quoteId, setQuoteId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  if (!isAuthenticated()) {
    window.location.href = '/login';
  }

  const handleLangChange = (e) => {
    setLang(e.target.value);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleTitle1Change = (e) => {
    setTitle1(e.target.value);
  };

  const handleTitle2Change = (e) => {
    setTitle2(e.target.value);
  };

  const handleTitle3Change = (e) => {
    setTitle3(e.target.value);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const handleImageUpload = async (e) => {
    setLoading(true);
    const file = e.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 900,
    }

    try {
      const compressedFile = await imageCompression(file, options)
      const formData = new FormData();
      formData.append('image', compressedFile);

      const res = await fetch('https://cuba-luxury-backend.onrender.com/imgUpload', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: formData,
      });

      if (!res.ok) {
        throw new Error('Image upload failed');
      }

      const imageUrl = await res.json();

      setSliderImages([...sliderImages, { image: imageUrl.url }]);
      setError('');
    } catch (error) {
      console.error(error);
      setError('Error uploading image');
    } finally {
      setLoading(false);
    }
  };

  const handleAddItem = () => {
    if (selectedType === 'title' && !title1 && !title2) {
      setError('At least one title is required for Title type');
      return;
    } else if (selectedType === 'title3' && !title3) {
      setError('Insert some text');
      return;
    } else if (selectedType === 'text' && !text) {
      setError('Insert some text');
      return;
    } else if (selectedType === 'slider' && sliderImages.length === 0) {
      setError('At least one image is required for gallery');
      return;
    }

    if ((selectedType === "title" || selectedType === "title3" ||
      selectedType === "text") && size === "") {
      setError('Size is required');
      return;
    }

    const newItem = {
      name,
      title: selectedType === 'title' ? { title1, title2 } : null,
      title3: selectedType === 'title3' ? title3 : null,
      text: selectedType === 'text' ? text : null,
      size,
      slider: selectedType === 'slider' ? sliderImages : [],
      interruption: selectedType === 'interruption' ? true : false,
    };

    setItems((prevItems) => [...prevItems, newItem]);

    setSelectedType('');
    setTitle1('');
    setTitle2('');
    setTitle3('');
    setText('');
    setSize('md');
    setSliderImages([]);
    setError('');
  };

  const handleRemoveItem = (index) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const quote = {
      name,
      language: lang,
      items,
    };

    try {
      setLoading(true);
      const res = await fetch('https://cuba-luxury-backend.onrender.com/page', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: JSON.stringify(quote),
      });

      if (!res.ok) {
        console.log(res)
        throw new Error("Can't submit, please try again");
      }

      const { id } = await res.json();
      setQuoteId(id);
      setError('');
    } catch (error) {
      console.error(error);
      setError('Error submitting quote');
    } finally {
      setLoading(false);
    }
  };

  const scrollBottom = () => {
    document.getElementById('bottom').scrollIntoView({ behavior: 'smooth' });
  };

  const scrollTop = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    const updatedItems = Array.from(items);
    const [removed] = updatedItems.splice(source.index, 1);
    updatedItems.splice(destination.index, 0, removed);

    setItems(updatedItems);
  };

  return (
    <div className="quote-form">
      {loading && <Loading />}
      <div className="scroll">
        <button className="scroll__bottom" onClick={scrollTop}>
          <img src={arrow} alt="arrow" />
        </button>
        <button className="scroll__top" onClick={scrollBottom}>
          <img src={arrow} alt="arrow" />
        </button>
      </div>
      <h1>Page Generator</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Nome Pagina:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </label>
        <label>
          Lingua:
          <select value={lang} onChange={handleLangChange} required>
            <option value="">Seleziona la lingua</option>
            <option value="it">Italiano</option>
            <option value="en">Inglese</option>
          </select>
        </label>
        <label>
          Item Type:
          <select value={selectedType} onChange={handleTypeChange}>
            <option value="">Select an item type</option>
            <option value="title">Title 1/2</option>
            <option value="title3">Title 3</option>
            <option value="text">Text</option>
            <option value="slider">Gallery</option>
            <option value="interruption">Interruption</option>
          </select>
        </label>
        {selectedType === 'title' && (
          <>
            <label>
              Title 1:
              <input type="text" value={title1} onChange={handleTitle1Change} />
            </label>
            <label>
              Title 2:
              <input type="text" value={title2} onChange={handleTitle2Change} />
            </label>

            <select name="size" defaultValue="md" onChange={handleSizeChange}>
              <option value="" disabled>Select font</option>
              <option value="sm">Small</option>
              <option value="md">Medium</option>
              <option value="lg">Large</option>
            </select>
          </>
        )}
        {selectedType === 'title3' && <>
          <label>
            Title 3:
            <input type="text" value={title3} onChange={handleTitle3Change} />
          </label>

          <select name="size" defaultValue="md" onChange={handleSizeChange}>
            <option value="" disabled>Select font</option>
            <option value="sm">Small</option>
            <option value="md">Medium</option>
            <option value="lg">Large</option>
          </select>
        </>
        }
        {selectedType === 'text' && <>
          <label>
            Text:
            <textarea value={text} onChange={handleTextChange} />
          </label>

          <select name="size" defaultValue="md" onChange={handleSizeChange}>
            <option value="" disabled>Select font</option>
            <option value="sm">Small</option>
            <option value="md">Medium</option>
            <option value="lg">Large</option>
          </select>
        </>
        }
        {selectedType === 'slider' && (
          <label>
            Slider Images:
            <input type="file" accept="image/*" onChange={handleImageUpload} />
          </label>
        )}
        {error && <p className="error">{error}</p>}
        <div className="slider_container" style={{ display: "flex", flexWrap: "wrap" }}>
          {sliderImages && sliderImages.map((image, i) => <a href={image.image}>
            <img src={image.image} alt="gallery" style={{ width: "250px", marginRight: "25px" }} />
          </a>)}
        </div>
        <button type="button" onClick={handleAddItem} disabled={!selectedType}>
          Add Item
        </button>
        <div className="item-list">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="item-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <div
                          className="item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {item.title && (
                            <>
                              {item.title.title1 && <p>Title 1: {item.title.title1}</p>}
                              {item.title.title2 && <p>Title 2: {item.title.title2}</p>}
                              <p>Font Size: {item.size}</p>
                            </>
                          )}
                          {item.title3 && <>
                            <p>Title 3: {item.title3}</p>
                            <p>Font Size: {item.size}</p>
                          </>}
                          {item.text && <>
                            <p>Text: {item.text}</p>
                            <p>Font Size: {item.size}</p>
                          </>
                          }
                          {item.slider.length > 0 && (
                            <div>
                              <p>Slider Images:</p>
                              {item.slider.map((image, i) => (
                                <img src={image.image} alt={`Slider ${i + 1}`} style={{ width: '200px' }} key={i} />
                              ))}
                            </div>
                          )}
                          {item.interruption && <p>Interruption</p>}
                          <button type="button" onClick={() => handleRemoveItem(index)}>
                            Remove Item
                          </button>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <button type="submit">Submit</button>
        {
          quoteId && (
            <p>
              PAGE SAVED
            </p>
          )
        }
      </form >
      <div id="bottom"></div>
    </div >
  );
}

export default PagesGenerator;
