import React, { useState, useEffect } from 'react';
import '../../css/admin/quotesView.css';
import Loading from '../Loading';
import { isAuthenticated } from './Auth';

const QuotesView = () => {
  const [quotes, setQuotes] = useState([]);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (!isAuthenticated()) {
    window.location.href = '/login';
  }

  useEffect(() => {
    setIsLoading(true);
    fetch('https://cuba-luxury-backend.onrender.com/quote', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
      }
    })
      .then((res) => {
        if (!res.ok) setError(true);
        return res.json();
      })
      .then((data) => {
        if (!error) setQuotes(data);
        setIsLoading(false);
      })
      .catch((err) => setError(true));
  }, [refresh]);

  const handleDelete = async (id) => {
    try {
      const res = await fetch(`https://cuba-luxury-backend.onrender.com/quote/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      })
      if (res.ok) {
        setRefresh(!refresh)
      } else {
        setError(true)
      }

    } catch (error) {
      setError(true)
    }
  }

  const confirmDelete = (quoteId) => {
    const shouldDelete = window.confirm('Sei sicuro di voler eliminare questo preventivo?');
    if (shouldDelete) {
      handleDelete(quoteId);
    }
  }

  const handleDuplicate = async (id) => {
    setIsLoading(true)
    try {
      const res = await fetch(`https://cuba-luxury-backend.onrender.com/quote/duplicate/${id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      })
      if (res.ok) {
        setRefresh(!refresh)
      } else {
        setError(true)
      }
    } catch (error) {
      setError(true)
    }
    setIsLoading(false)
  }

  return (
    <div className='quotesView'>
      <h1>Lista preventivi</h1>
      {isLoading && <Loading />}
      <div className='quoteContainer'>
        {quotes.length > 0 &&
          quotes.map((quote) => (
            <div className='quoteView' key={quote._id}>
              {quote.name && <p style={{ marginBottom: "0", color: "#bda546", fontWeight: "bold", fontSize: "20px" }}>{quote.name}</p>}
              {quote.customerName ? (
                <p><span>Cliente:</span> {quote.customerName}</p>
              ) : <p>Preventivo anonimo</p>}
              <a className='quoteView__quote' href={quote.link ? `/quote/${quote.link}` : `/quote/${quote._id}`} target="_blank" rel="noreferrer">Preventivo</a>
              <a className='quoteView__edit' href={`/admin/quotesEditor/${quote._id}`} target="_blank" rel='noreferrer'>Edit</a>
              <button className='duplicate' onClick={() => handleDuplicate(quote._id)}>Duplicate</button>
              <button className='delete' onClick={() => confirmDelete(quote._id)}>Delete</button>
            </div>
          ))}
      </div>
      {error && <p className='error'>Si è verificato un errore, riprova</p>}
    </div>
  );
};

export default QuotesView;