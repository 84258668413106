import React from 'react'

const SizeInput = ({ handleNewItem }) => {
    return (
        <label>
            Font size:
            <select
                name="size"
                defaultValue="md"
                onChange={handleNewItem}
            >
                <option value="" disabled>Select Size</option>
                <option value="sm">Small</option>
                <option value="md">Medium</option>
                <option value="lg">Large</option>
            </select>
        </label>
    )
}

export default SizeInput