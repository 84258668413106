import React from 'react'
import { Link } from 'react-router-dom';
import { isAuthenticated } from './Auth';
import "../../css/admin/adminMenu.css"

const AdminMenu = () => {

  if (!isAuthenticated()) {
    window.location.href = '/login';
  }

  return (
    <div className='quotesMenu'>
      <h1>Page Selector</h1>
      <div className="quotesMenu__item">
        <Link to="/admin/quotesView">
          Visualizza preventivi
        </Link>
        <Link to="/admin/quotesGenerator">
          Genera preventivo
        </Link>
      </div>
      <div className="quotesMenu__item">
        <Link to="/admin/pagesView">
          Visualizza pagine
        </Link>
        <Link to="/admin/pagesEditor">
          Genera pagina
        </Link>
      </div>
      <div className="quotesMenu__item">
        <Link to="/admin/qrView">
          Visualizza QR
        </Link>
      </div>
    </div>
  );
};

export default AdminMenu